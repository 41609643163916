import Button from 'aac-components/components/Button';
import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';
import Link from 'next/link';
import React from 'react';
import Image from 'next/image';

const InsuranceSection = () => {
    return (
        <section className="insurance-section">
            <h2>Check Your Coverage</h2>
            <p>
                Check to see if your insurance is in-network at an American Addiction
                Centers location.
            </p>
            <div className="insurance-section__button">
                <Button
                    style={{minWidth: '100%', fontWeight: 'normal'}}
                    theme="invert"
                    onClick={(e) =>
                        window && window.AAC && window.AAC.openSlideInSsvob(e)
                    }>
                    Check Coverage Now
                </Button>
            </div>
            <div className="insurance-section__providers">
                {providerCards.map((card) => {
                    return (
                        <React.Fragment key={card?.image}>
                            <Link href={card?.href} title={card?.title}>
                                <div className="insurance-section__image">
                                    <Image
                                        src={card?.image}
                                        width={0}
                                        height={0}
                                        style={{
                                            width: 'auto',
                                            height: 'auto',
                                            maxWidth: '120px',
                                        }}
                                        sizes="200px"
                                    />
                                </div>
                            </Link>
                        </React.Fragment>
                    );
                })}
            </div>
            <style jsx>
                {`
                    .insurance-section {
                        margin: 0 auto;
                        max-width: ${MAX_WIDTH_PX};
                        padding: 64px 15px;
                        text-align: center;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance-section {
                            padding: 120px 0;
                        }
                    }
                    .insurance-section__button {
                        max-width: 100%;
                        margin: 0 auto 40px auto;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance-section__button {
                            max-width: fit-content;
                        }
                    }
                    .insurance-section__image {
                        position: relative;
                    }
                    .insurance-section__providers {
                        display: grid;
                        grid-template-columns: auto auto;
                        justify-content: center;
                        align-items: center;
                        grid-gap: 16px 40px;
                        flex-wrap: wrap;
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        .insurance-section__providers {
                            display: flex;
                            grid-gap: 20px 40px;
                            justify-content: center;
                            align-items: center;
                            padding-bottom: 0;
                            overflow: hidden;
                        }
                    }
                `}
            </style>
        </section>
    );
};
export default InsuranceSection;

const commonsProviderFolder =
    'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/providers';
const providerCards = [
    {
        title: 'Aetna',
        image: `${commonsProviderFolder}/aetna.svg`,
        href: `/insurance-coverage/aetna`,
    },
    {
        title: 'Ambetter',
        image: `${commonsProviderFolder}/ambetter.svg`,
        href: `/insurance-coverage/ambetter`,
    },
    {
        title: 'Anthem',
        image: `${commonsProviderFolder}/anthem-2.png`,
        href: '/insurance-coverage/anthem',
    },
    {
        title: 'Blue Cross Blue Shield',
        image: `${commonsProviderFolder}/anthem-bcbs.svg`,
        href: `/insurance-coverage/blue-cross-blue-shield-association`,
    },
    {
        title: 'Carelon Behavioral Health Options',
        image: `${commonsProviderFolder}/carelon.svg`,
        href: '/insurance-coverage/carelon-behavioral-health',
    },
    {
        title: 'Cigna',
        image: `${commonsProviderFolder}/cigna.svg`,
        href: `/insurance-coverage/cigna-insurance`,
    },
    {
        title: 'Health Net',
        image: `${commonsProviderFolder}/health-net.png`,
        href: `/insurance-coverage/health-net`,
    },
    {
        title: 'Kaiser Permanente',
        image: `${commonsProviderFolder}/kaiser.png`,
        href: `/insurance-coverage/kaiser-permanente`,
    },
    {
        title: 'Magellan Health',
        image: `${commonsProviderFolder}/magellan-health.png`,
        href: `/insurance-coverage/magellan-insurance`,
    },
    {
        title: 'Oscar Health Plan',
        image: `${commonsProviderFolder}/oscar.png`,
        href: `/insurance-coverage/oscar-health-plan`,
    },
    {
        title: 'TRICARE',
        image: `${commonsProviderFolder}/tricare-2.png`,
        href: `/insurance-coverage/tricare`,
    },
    {
        title: 'Tufts',
        image: `${commonsProviderFolder}/tufts.svg`,
        href: `/insurance-coverage/tufts-health-plan`,
    },
    {
        title: 'United Healthcare',
        image: `${commonsProviderFolder}/united.svg`,
        href: `/insurance-coverage/unitedhealth-group`,
    },
    {
        title: 'VA Benefits (Veterans Affairs)',
        image: `${commonsProviderFolder}/va-2.png`,
        href: `/insurance-coverage/va-benefits`,
    },
];
